<template>
  <div class="simpleSchoolList">
    <div class="search_sList">
      <div class="top_opera">
        <el-form
          :inline="true"
          :model="formInline"
          class="demo-form-inline"
          label-width="80px"
        >
          <el-form-item label="管理学校">
            <el-select
              v-model="formInline.tenantId"
              placeholder="管理学校"
              filterable
              clearable
            >
              <el-option
                v-for="item in tableData"
                :key="item.tenantId"
                :label="item.tenantName"
                :value="item.tenantId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="学校编码">
            <el-input
              v-model="formInline.encoding"
              placeholder="学校编码"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="btn_area">
          <el-button
            class="mainBtn"
            @click="onSubmit"
            plain
            v-preventReClick
            >搜索</el-button
          >
          <el-button type="info" @click="resetEvent" plain>重置</el-button>
        </div>
      </div>
    </div>
    <div class="table_sList">
      <div class="opera_btnArea">
        <el-button
          class="mainBtn"
          @click="addManSchEvent"
          plain
          >添加管理学校</el-button
        >
      </div>
      <el-table
        :data="tableData"
        stripe
        border
        style="width: 100%"
        height="450"
        align="center"
      >
        <el-table-column
          v-for="(item, index) in columnList"
          :key="index"
          :prop="item.prop"
          :label="item.label ? item.label : '/'"
          :width="item.width"
          align="center"
        >
          <template slot-scope="scope">
            <template v-if="scope.column.property === 'tenantName'">
              <el-tooltip
                :content="scope.row[scope.column.property]"
                placement="top"
                effect="light"
                popper-class="pubToop"
                v-if="scope.row[scope.column.property] != 'total'"
              >
                <div class="ellipsis">{{ scope.row[scope.column.property] }}</div>
              </el-tooltip>
            </template>
            <span v-else-if="scope.row[scope.column.property] == null">/</span>
            <span v-else>
              {{ scope.row[scope.column.property] }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          align="center"
          width="100"
        >
          <template slot-scope="scope">
            <el-button @click="deleteEvent(scope.row)" type="text" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagination @changePage="changePage" :childMsg="childMsg"></Pagination>
    </div>
    <div class="btn_area">
      <el-button @click="closeEvent">关闭</el-button>
    </div>

    <handle
      :isShow="isShowDel"
      :handleText="handleText"
      @handlesEvent="doHandleEvent"
    >
      <div class="btn_area" slot="handle_btn">
        <el-button @click="doHandleEvent">关闭</el-button>
        <el-button type="primary" @click="deleStu">确定</el-button>
      </div>
    </handle>

    <examTransferDiy
      :isDiyTransfer="isDiyTransfer"
      :diyData="diyData"
      @showTransfer="showTransferEvent"
    >
      <template #extendHead>
        <div class="search_sList e-cus">
          <div class="top_opera">
            <el-form
              :inline="true"
              :model="cusForm"
              class="cus-form-inline"
              label-width="100px"
            >
              <el-form-item label="平台名称">
                <el-select
                  v-model="cusForm.tenantId"
                  placeholder="平台名称"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="item in copySchData"
                    :key="item.tenantId"
                    :label="item.tenantName"
                    :value="item.tenantId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="学校编码">
                <el-input
                  v-model="cusForm.encoding"
                  placeholder="学校编码"
                  clearable
                ></el-input>
              </el-form-item>
            </el-form>
            <div class="btn_area">
              <el-button class="mainBtn" @click="onSchSubmit" plain>搜索</el-button>
              <el-button type="info" plain @click="resetSchEvent">重置</el-button>
            </div>
          </div>
        </div>
      </template>
    </examTransferDiy>
  </div>
</template>

<script>
const Pagination = () => import('@/components/Pagination')
const handle = () => import('@/components/commonModule/handle')
const examTransferDiy = () => import('components/commonModule/administration_module/examTransferDiy')
export default {
  name: 'simpleSchoolList',
  components: {
    Pagination,
    handle,
    examTransferDiy
  },
  data () {
    return {
      formInline: {
        customerConfig: null,
        encoding: null,
        nature: null,
        page: 1,
        pageSize: 10,
        tenantId: null,
        provinceId: null,
      },
      cusForm: {
        customerConfig: null,
        encoding: null,
        nature: null,
        page: 1,
        pageSize: 500,
        tenantId: null,
        provinceId: null
      },
      tableData: [],
      columnList: [
        {
          prop: "tenantName",
          label: "平台名称",
        },
        {
          prop: "encoding",
          label: "学校编码",
          width: 160
        },
        {
          prop: "peopleNum",
          label: "学校人数",
          width: 120
        }
      ],
      childMsg: {
        current: 1,
        size: 10,
        total: 0,
      },
      isShowDel: false,
      handleText: '确定是否要删除该项？',
      diyData: {
        title: ["可选学校", "已选学校"],
        ids: [],
        serchName: '请输入平台名称',
        transferData: [],
        dialogDiytitle: "选择学校",
        sizeStyle: { width: "900px", height: "730px" },
        propsData: {
          key: 'tenantId',
          label: 'tenantName'
        }
      },
      isDiyTransfer: false,
      rowData: {},
      copySchData: [],
    }
  },
  methods: {
    onSubmit() {
      this.formInline.page = 1;
      this.formInline.pageSize = 10;
      this.getTableData();
    },
    // 重置
    resetEvent() {
      this.formInline.tenantId = null
      this.formInline.encoding = null
    },
    onSchSubmit() {
      this.addManSchEvent()
    },
    // 重置
    resetSchEvent() {
      this.cusForm.tenantId = null
      this.cusForm.encoding = null
    },
    sendData(obj) {
      Object.assign(this.formInline, obj)
      Object.assign(this.cusForm, obj)
      this.getTableData()
    },
    // 获取表格数据
    getTableData() {
      this.$request.manageSchoolList(this.formInline).then(res => {
        if (res.data.code == 0) {
          let { records, current, size, total } = res.data.data;
          this.tableData = records;
          this.childMsg = {
            current,
            size,
            total
          };
        }
      });
    },
    changePage(val) {
      this.childMsg = val;
      this.formInline.currentPage = val.current;
      this.formInline.pageSize = val.size;
      this.getTableData();
    },
    // 添加管理学校
    addManSchEvent() {
      this.$request.manageSchoolAddList(this.cusForm).then(res => {
        if (res.data.code == 0) {
          this.diyData.transferData = res.data.data
          if (!this.isDiyTransfer) {
            this.copySchData = res.data.data
            this.showTransferEvent()
          }
        }
      })
    },
    showTransferEvent(val = {}) {
      if (val.hasOwnProperty("ids")) {
        // console.log(val,8888)
        this.diyData.ids = []
        this.addSchool(val.ids)
      }
      this.resetSchEvent()
      this.isDiyTransfer = !this.isDiyTransfer
    },
    addSchool(tenantIds) {
      let obj = {
        customerConfigId: this.formInline.customerConfig,
        tenantIds
      }
      this.$request.addManageSchool(obj).then(res => {
        if (res.data.code == 0) {
          this.Success('操作成功')
          this.$set(this.diyData, 'ids', [])
          this.getTableData()
        }
      })
    },
    deleteEvent(row) {
      this.rowData = row
      this.doHandleEvent()
    },
    doHandleEvent() {
      this.isShowDel = !this.isShowDel
    },
    deleStu() {
      let obj = {
        tenantId: this.rowData.tenantId,
        customerConfigId: this.cusForm.customerConfig
      }
      this.$request.deleteManageSchool(obj).then(res => {
        if (res.data.code == 0) {
          this.doHandleEvent()
          this.Success(res.data.msg || '操作成功')
          this.getTableData()
        }
      })
    },
    closeEvent() {
      this.$emit('dialogSchEvent')
    }
  }
}
</script>

<style lang="less" scoped>
  .simpleSchoolList {
    .table_sList {
      padding-top: 0;
      margin-top: 0;
    }
    .btn_area {
      text-align: center;
    }
    .e-cus {
      padding: 28px 0;
    }
  }
</style>