import { render, staticRenderFns } from "./simpleSchoolList.vue?vue&type=template&id=882ae424&scoped=true"
import script from "./simpleSchoolList.vue?vue&type=script&lang=js"
export * from "./simpleSchoolList.vue?vue&type=script&lang=js"
import style0 from "./simpleSchoolList.vue?vue&type=style&index=0&id=882ae424&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "882ae424",
  null
  
)

export default component.exports